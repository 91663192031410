import * as React from 'react';
import { AiOutlineRead } from 'react-icons/ai';
import { MDXRenderer } from 'gatsby-plugin-mdx';

interface GlossaryArticleProps {
  title: string
  body: string
  search: string
}

const GlossaryArticle: React.FC<GlossaryArticleProps> = ({
    title,
    body,
}: GlossaryArticleProps): JSX.Element => (
    <article className="glossary-article">
        <h3>
            <AiOutlineRead />
            <span>{title}</span>
        </h3>
        <MDXRenderer>{body}</MDXRenderer>
    </article>
);

export default GlossaryArticle;
