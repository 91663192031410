import * as React from "react"
import { graphql } from "gatsby"
// Icons
import { AiOutlineSearch } from "react-icons/ai"
// Types
import { GlossaryArticleProperties } from "../components/glossary/glossary-interface"
// Components
import useLocalizationKeys from "../components/localization-utils/useLocalizationKeys"
import useTranslation from "../components/localization-utils/useTranslation"
import SEO from "../components/seo"
import GlossaryArticle from "../components/glossary/glossary-article"
import GlossaryNoResults from "../components/glossary/glossary-no-results"

// Glossary section of the site dev.tinkermode.com/glossary
const Glossary: React.FC<any> = ({ data: { allMdx } }: any): JSX.Element => {
  const { glossary, SEO: s } = useLocalizationKeys()

  const articles: JSX.Element[] = allMdx.nodes.map(
    (article: GlossaryArticleProperties): JSX.Element => (
      <GlossaryArticle
        search={`${article.frontmatter.title
          .toLowerCase()
          .trim()}${article.rawBody.toLowerCase().trim()}`}
        key={`${article.fields.locale}${article.frontmatter.title}`}
        title={article.frontmatter.title}
        body={article.body}
      />
    )
  )

  const [searchText, setSearchText] = React.useState<string>("") // Capture search text from user
  const [filteredArticles, setFilteredArticles] = React.useState<any[]>(
    articles
  ) // Filter article by search text

  // Filter through the article titles and body from the search text
  React.useEffect(() => {
    const filtered = articles.filter((article: any) =>
      article.props.search.includes(searchText)
    )
    setFilteredArticles(filtered)
  }, [searchText])

  return (
    <>
      <SEO title={useTranslation(s.glossary.title)} description={useTranslation(s.glossary.description)}/>
      <div className="glossary-page-wrapper">
        <div className="page-header">
          <h1>{useTranslation(glossary.title)}</h1>
          <p>{useTranslation(glossary.subtitle)}</p>
        </div>
        <section id="glossary-body">
          <div className="search-input-wrapper">
            <AiOutlineSearch id="search-glossary-icon" />
            <input
              type="text"
              placeholder={useTranslation(glossary.searchPlaceholder)}
              onChange={e => setSearchText(e.target.value.trim().toLowerCase())}
            />
          </div>
          {filteredArticles.length ? (
            filteredArticles
          ) : (
            <GlossaryNoResults searchTerm={searchText} />
          )}
        </section>
      </div>
    </>
  )
}

// Retrieve all glossary articles from /src/pages/glossary
export const glossaryQuery = graphql`
  query GlossaryArticleQuery($locale: String!, $dateFormat: String!) {
    allMdx(
      sort: { fields: frontmatter___title, order: ASC }
      filter: {
        fields: { locale: { eq: $locale } }
        frontmatter: { type: { eq: "glossary" } }
      }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: $dateFormat)
        }
        body
        rawBody
        fields {
          locale
        }
      }
    }
  }
`

export default Glossary
