import * as React from 'react';
import LocalizedLink from '../links/localizedLink';

interface GlossaryNoResultsProps {
  searchTerm: string
}

const GlossaryNoResults: React.FC<GlossaryNoResultsProps> = ({
    searchTerm,
}: GlossaryNoResultsProps): JSX.Element => (
    <div className="no-results">
        <p>Oops, we don&#39;t have any definitions for &#39;{searchTerm}&#39;.</p>
        <p>
      If you think &#39;{searchTerm}&#39; should be in our glossary, please{' '}
            <LocalizedLink className="emphasis-text" to="/contact-us">
        let us know
            </LocalizedLink>
      .
        </p>
    </div>
);

export default GlossaryNoResults;
